import React from 'react'
import { Link } from 'react-router-dom'

const Cta = () => {
    return (
        <section id="cta" className="cta">
            <div className="container" data-aos="zoom-in">
                <div className="text-center">
                    <h3>Call To Action</h3>
                    <p> Contact us today to schedule a consultation and learn how our IT and network services can help you achieve your business goals. Our team of experienced professionals is ready to provide you with customized solutions that meet your unique needs. Don't wait, contact us now and take your business to the next level!</p>
                    <Link className="cta-btn" to="/contact">Call To Action</Link>
                </div>
            </div>
        </section>
    )
}

export default Cta