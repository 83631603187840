import axios from 'axios'
import React, { useEffect, useState } from 'react'
import validation from './Validation';
import Alert from './Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [error, setError] = useState({})
    const [data, setData] = useState({
        name: "",
        email: "",
        city: "",
        zip_code: "",
        message: ""
    })
    const [alert, setAlert] = useState(null)
    const formHandle = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const submitForm = (e) => {
        e.preventDefault();
        setError(validation(data));

        if (Object.keys(error).length === 0 && (data.email !== "")) {
            axios.post("http://localhost:3001/api/enquiry", data).then((res) => {

                setData({
                    name: "",
                    email: "",
                    city: "",
                    zip_code: "",
                    message: ""
                })
                toast.success('successful', {autoClose:3000})
                // setAlert("success")
            })
        }
    }
    // useEffect(() => {

    // }, [error])
    return (
        <section id="contact" className="contact section-bg">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Contact</h2>
                    <p>Contact Us</p>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="info-box">
                                    <i className="bx bx-map" />
                                    <h3>Our Address</h3>
                                    <p>224 Shankar Colony Telikheda mhow, IN 453441</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box mt-4">
                                    <i className="bx bx-envelope" />
                                    <h3>Email Us</h3>
                                    <p>info@atozinfinityservices.com<br />contact@atozinfinityservices.com</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box mt-4">
                                    <i className="bx bx-phone-call" />
                                    <h3>Call Us</h3>
                                    <p>+91 732 4292799<br />+91 863 8540558</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        {alert && <Alert />}
                        <form onSubmit={submitForm} className="php-email-form">
                            <div className="form-row">
                                <div className="col form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:2" data-msg="Please enter at least 2 chars" onChange={formHandle} value={data.name} />
                                    {error.name && <p style={{ color: "red", fontSize: "13px" }}>{error.name}</p>}
                                    <div className="validate" />
                                </div>
                                <div className="col form-group">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" onChange={formHandle} value={data.email} />
                                    {error.email && <p style={{ color: "red", fontSize: "13px" }}>{error.email}</p>}
                                    <div className="validate" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col form-group">
                                    <input type="text" name="city" className="form-control" id="city" placeholder="Your city" data-rule="minlen:2" data-msg="Please enter at least 2 chars" onChange={formHandle} value={data.city} />
                                    <div className="validate" />
                                </div>
                                <div className="col form-group">
                                    <input type="number" className="form-control" name="zip_code" id="zip_code" placeholder="Your zip code" data-rule="minlen:6, maxlen:7" data-msg="Please enter a valid zip code" onChange={formHandle} value={data.zip_code} />
                                    {error.zip_code && <p style={{ color: "red", fontSize: "13px" }}>{error.zip_code}</p>}
                                    <div className="validate" />
                                </div>
                            </div>

                            <div className="form-group">
                                <textarea className="form-control" name="message" rows={5} data-rule="required" data-msg="Please write something for us" placeholder="Message" defaultValue={""} onChange={formHandle} value={data.message} />
                                <div className="validate" />
                            </div>
                            <div className="mb-3">
                                <div className="loading">Loading</div>
                                <div className="error-message" />
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center"><button type="submit"  >Send Message</button></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
