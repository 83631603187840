import React from 'react'
import { Link } from 'react-router-dom'
import WhatsAppButton from './WhatsAppButton'

const Footer = () => {
    return (
        <div>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="footer-info">
                                    <h3>A to Z Infinity Services</h3>
                                    <p className="pb-3"><em>Simplifying your IT challenges: Explore the World of IT, Network, and Systems Engineering with Us!</em>
                                    </p>
                                    <p>
                                        224 Shankar Colony Telikheda mhow <br />
                                        Pin 453441, INDIA<br /><br />
                                        <strong>Phone:</strong> +917324292799<br />
                                        <strong>Email:</strong> info@atozinfinityservices.com<br />
                                    </p>
                                    <div className="social-links mt-3">
                                        <Link to="#" className="twitter"><i className="bx bxl-twitter" /></Link>
                                        <Link to="#" className="facebook"><i className="bx bxl-facebook" /></Link>
                                        <Link to="#" className="instagram"><i className="bx bxl-instagram" /></Link>
                                        <Link to="#" className="google-plus"><i className="bx bxl-skype" /></Link>
                                        <Link to="#" className="linkedin"><i className="bx bxl-linkedin" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/" > Home</Link></li>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/about">About us</Link></li>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/services">Services</Link></li>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/team">Team</Link></li>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/contact">Contact</Link></li>
                                    {/* <li><i className="bx bx-chevron-right" /> <Link to="#">Terms of service</Link></li> */}
                                    {/* <li><i className="bx bx-chevron-right" /> <Link to="#">Privacy policy</Link></li> */}
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/datanetwork">Data network</Link></li>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/networksecurity">Network security</Link></li>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/wireless">Wireless</Link></li>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/iptelephony">IP Telephony</Link></li>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/digitalmarketing">Digital Marketing</Link></li>
                                    <li><i className="bx bx-chevron-right" /> <Link to="/devops">DevOps Solutions</Link></li>
                                </ul>
                            </div>
                            {/* <div className="col-lg-4 col-md-6 footer-newsletter">
                            <h4>Our Newsletter</h4>
                            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                            <form action method="post">
                                <input type="email" name="email" /><input type="submit" defaultValue="Subscribe" />
                            </form>
                        </div> */}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        © Copyright <strong><span>A to Z Infity Services</span></strong>. All Rights Reserved
                    </div>
                    {/* <div className="credits">
                    Designed by <Link to="https://bootstrapmade.com/">BootstrapMade</Link>
                </div> */}
                </div>
            </footer>
            <div id="preloader"></div>
            <a href="#" className="back-to-top"><i className="icofont-simple-up"></i></a>
            <WhatsAppButton />
            
        </div>
    )
}

export default Footer