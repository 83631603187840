import React, { useEffect } from 'react'

const NetworkSecurity = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const imgStyle = {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        margin: "auto"
    }
    return (
        <section id="networkSecurity" className="networkSecurity">

            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Network Security</h2>
                    <p>Network Security</p>
                </div>
                <div className="row content">
                    <div className="col-lg-6">
                        <p>
                            Network security is a critical component of any modern business or organization. With so much valuable information and sensitive data being transmitted over networks every day, it's essential to ensure that your network is secure from unauthorized access, data breaches, and other security threats. <br />
                            There are many different aspects to network security, including hardware and software security, access control, data encryption, and more. Some common network security measures include firewalls, antivirus and malware protection, intrusion detection and prevention systems, and secure remote access.

                        </p>
                        <img src="/home/assets/img/solutions/network_security_1.jpg" alt="Example img" style={imgStyle}></img>

                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <img src="/home/assets/img/solutions/network_security_2.jpg" alt="Example img" style={imgStyle}></img>
                        <p><br />
                            At its core, network security is about protecting the confidentiality, integrity, and availability of your data and systems. By implementing robust network security measures, you can help to prevent data breaches, protect your sensitive information from unauthorized access, and ensure that your business or organization remains compliant with relevant regulations and industry standards.
                        </p>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-lg-6">
                        <div className="section-title">
                            {/* <h2>Network Security</h2> */}
                            <p>Firewalls</p>
                        </div>
                        <p>
                            A firewall is a critical component of any network security strategy. Simply put, a firewall is a hardware or software device that is designed to control access to a network or computer system, by examining incoming and outgoing network traffic and determining whether to allow or block that traffic based on pre-defined security rules.

                        </p><br />
                        <img src="/home/assets/img/solutions/firewall_1.jpg" alt="Example img" style={imgStyle}></img>

                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <div className="section-title">
                            <p>VPNs</p>
                        </div>
                        <img src="/home/assets/img/solutions/vpn-shield-1.jpg" alt="Example img" style={imgStyle}></img>
                        <p><br />
                            A Virtual Private Network (VPN) is a secure connection between two or more devices or networks over the internet. VPNs use encryption and tunneling protocols to create a secure, private connection that allows users to access network resources and applications from anywhere in the world, while ensuring the confidentiality and integrity of their data.
                        </p>
                        <p>
                            VPNs are widely used by businesses and organizations to provide secure remote access for employees, contractors, and partners. They are also used to connect branch offices and to securely transmit sensitive data between different locations.
                        </p>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-lg-6">
                        <div className="section-title">
                            <p>Emial Security</p>
                        </div>
                        <p>
                            Email is a vital communication tool for businesses and individuals, but it also represents a major security risk. Malicious actors can use email to launch phishing attacks, distribute malware, and steal sensitive data. To protect your business and your customers from these threats, it's important to implement robust email security measures.

                        </p><br />
                        <img src="/home/assets/img/solutions/email_security_1.jpg" alt="Example img" style={imgStyle}></img>

                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <div className="section-title">
                            <p>Server Security</p>
                        </div>
                        <img src="/home/assets/img/solutions/server_security_1.jpg" alt="Example img" style={imgStyle}></img>
                        <p><br />
                            At our company, we understand the critical importance of ensuring your servers are secure and protected against any potential threats. Our team of experienced professionals can assist you in designing and implementing a comprehensive server security solution that meets your specific business needs.
                        </p>
                        <p>
                            Our approach to server security begins with a thorough assessment of your current infrastructure and identifying any vulnerabilities or weaknesses. From there, we work with you to develop and implement a customized security plan that includes measures such as access control, network segmentation, intrusion detection and prevention, data encryption, and more.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default NetworkSecurity