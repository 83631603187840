import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      const imgStyle = {
        maxWidth: "100%",
        height: "83px",
        display: "block",
       
    }
    return (
        <header id="header" className="fixed-top">
            <div className="container d-flex align-items-center">
                <img className="logo mr-auto" style={imgStyle} src='/logo_image.png' alt='logo'  />
                {/* <h1 className="logo mr-auto"><Link to="#">AtoZInfityServices</Link></h1> */}
                <nav id="nav-menu" className="nav-menu d-none d-lg-block">
                    <ul>
                        <li className="active"><Link to="/">Home</Link></li>
                        <li><Link to="about" >About</Link></li>
                        <li><Link to="services"  >Services</Link></li>
                        <li className="drop-down"><Link to="/solutions">Solutions</Link>
                            <ul>
                                <li className='drop-down'><Link to="/datanetwork">Data Network</Link>
                                    <ul>
                                        <li><Link to="/datanetwork">Switching</Link></li>
                                        <li><Link to="/datanetwork">Routing</Link></li>
                                        <li><Link to="/datanetwork">High Availability</Link></li>

                                    </ul>
                                </li>
                                <li className="drop-down"><Link to="/networksecurity">Network Security</Link>
                                    <ul>
                                        <li><Link to="/networksecurity">Firewalls</Link></li>
                                        <li><Link to="/networksecurity">Intrusion Prevention / Detection Systems</Link></li>
                                        <li><Link to="/networksecurity">VPNs</Link></li>
                                        <li><Link to="/networksecurity">Unified Threat Management</Link></li>
                                        <li><Link to="/networksecurity">Email Security</Link></li>
                                        <li><Link to="/networksecurity">Server Security</Link></li>
                                    </ul>
                                </li>
                                <li className="drop-down"><Link to="/wireless">Wireless</Link>
                                    <ul>
                                        <li><Link to="/wireless">High Speed WIFI Zone Deployment</Link></li>
                                        <li><Link to="/wireless">Point to Point RF Link Establishment</Link></li>
                                        <li><Link to="/wireless">Wireless Internet Service Provider Solutions</Link></li>
                                        <li><Link to="/wireless">Bandwidth Management for WISP Network</Link></li>

                                    </ul>
                                </li>
                                <li className="drop-down"><Link to="/iptelephony">IP Telephony</Link>
                                    <ul>
                                        <li><Link to="/iptelephony">Voice Over IP Telephony</Link></li>
                                        <li><Link to="/iptelephony">Video Over IP Conferencing</Link></li>
                                        <li><Link to="/iptelephony">Web Conferencing / Meetings</Link></li>
                                        <li><Link to="/iptelephony">Contact Center Deployment</Link></li>

                                    </ul>
                                </li>
                                <li className="drop-down"><Link to="/endpointsecurity">Endpoint Security</Link>
                                    <ul>
                                        <li><Link to="/endpointsecurity">Standalone Antivirus</Link></li>
                                        <li><Link to="/endpointsecurity">Centralized Antivirus Solutions</Link></li>


                                    </ul>
                                </li>
                                {/* <li className="drop-down"><Link to="#">Automation</Link>
                                    <ul>
                                        <li><Link to="#">Water Distribution Automation</Link></li>
                                        <li><Link to="#">Water Irrigation Automation</Link></li>
                                        <li><Link to="#">Street Light Automation</Link></li>
                                        <li><Link to="#">City Surveillance Automation</Link></li>
                                        <li><Link to="#">SMART Parking Automation</Link></li>
                                    </ul>
                                </li> 
                                <li className="drop-down"><Link to="#">Data Center Network</Link>
                                    <ul>
                                        <li><Link to="#">Storage Area Network</Link></li>
                                        <li><Link to="#">High Speed Data Center Deployment</Link></li>
                                        <li><Link to="#">Unified Computing System</Link></li>
                                    </ul>
                                </li>*/}
                                <li><Link to="/devops">DevOps Solutions</Link></li>
                                <li><Link to="/digitalmarketing">Digital Marketing</Link></li>
                                <li className="drop-down"><Link to="#">Data Center Network</Link>
                                    <ul>
                                        <li><Link to="#">Storage Area Network</Link></li>
                                        <li><Link to="#">High Speed Data Center Deployment</Link></li>
                                        <li><Link to="#">Unified Computing System</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="#">Desktop Virtulization</Link></li>
                                <li className="drop-down"><Link to="#">Servers</Link>
                                    <ul>
                                        <li><Link to="#">DHCP | DNS</Link></li>
                                        <li><Link to="#">Domain Controllers</Link></li>
                                        <li><Link to="#">Active Directory</Link></li>
                                        <li><Link to="#">Network Monitoring</Link></li>
                                        <li><Link to="#">Syslog Servers</Link></li>
                                        <li><Link to="#">Proxy Servers</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="#">CCTV Surveillance</Link></li>
                                <li><Link to="#">Biometric Access Control</Link></li>
                            </ul>
                        </li>
                        {/* <li><Link to="/portfolio" id="portfolio" >Portfolio</Link></li> */}
                        <li><Link to="/clients" id="clients" >Clients</Link></li>
                        <li><Link to="/team" id="team">Team</Link></li>
                        <li><Link to="/contact" id="contact">Contact</Link></li>
                    </ul>
                </nav>
                {/* <Link to="#about" className="get-started-btn">Get Started</Link> */}
            </div>
        </header>
    )
}

export default Header