import React, { useEffect } from 'react'

const Wireless = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const imgStyle = {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        margin: "auto"
    }
    return (
        <section id="wireless" className="wireless">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Wireless</h2>
                    <p>Wireless</p>
                </div>
                <div className="row content">
                    <div className="col-lg-6">
                        <p>
                            At our company, we specialize in providing comprehensive wireless network solutions to businesses of all sizes. Our team of experienced professionals can help you design and implement a wireless network that meets your specific needs, whether you're looking to support a small team of employees or a large enterprise-scale operation.
                        </p>
                        <img src="/home/assets/img/solutions/wifi-zone-1.jpg" alt="Example img" style={imgStyle} />
                        <p><br/>
                            We understand that a reliable and high-performing wireless network is critical to the success of your business. That's why we take a comprehensive approach to our wireless solutions, including network design, installation, and ongoing support and maintenance services.
                        </p>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <img src="/home/assets/img/solutions/wifi-zone.jpg" alt="Example img" style={imgStyle} />
                        <p><br/>
                            Our wireless solutions are designed to provide fast, reliable, and secure connectivity to support your business operations. We utilize the latest technologies and best practices to ensure your wireless network is optimized for performance and security, with features such as advanced encryption protocols, access controls, and intrusion detection and prevention.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Wireless