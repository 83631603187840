import React, { useEffect } from 'react'

const DevopsSolution = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const imgStyle = {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        margin: "auto"
    }
    return (
        <section id="devopssolution" className="devopssolution">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>DevOps Solutions</h2>
                    <p>DevOps Solutions</p>
                </div>
                <div className="row content">
                    <div className="col-lg-6">
                        <p>
                            Our team of experts can help you automate and streamline your software development pipeline, from code deployment to monitoring and feedback. With our DevOps solutions, you can:
                        </p>
                        <ul>
                            <li><i className="ri-check-double-line"></i> Accelerate your software development lifecycle.</li>
                            <li><i className="ri-check-double-line"></i> Increase the efficiency and effectiveness of your teams.</li>
                            <li><i className="ri-check-double-line"></i> Reduce the risk of errors and downtime.</li>
                            <li><i className="ri-check-double-line"></i> Improve the quality of your software releases.</li>
                            <li><i className="ri-check-double-line"></i> Enhance the visibility and transparency of your development process.</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <img src="/home/assets/img/solutions/devops_1.png" alt="Example img" style={imgStyle}></img>
                        <p>
                            Whether you are a startup or an established enterprise, our DevOps solutions can help you achieve your software development goals faster and with greater confidence. Contact us today to learn more about how we can help you improve your software development processes with DevOps.
                        </p>
                        {/* <Link to="#" className="btn-learn-more">Learn More</Link> */}
                    </div>
                </div>

            </div>
        </section>
    )
}
export default DevopsSolution
