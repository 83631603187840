import React from 'react'

const Alert = () => {
    return (
        <div className="alert alert-warning alert-dismissible fade show" role="alert">
            <strong>Success!</strong> Your message sent successfully.
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">&#x2715;</button>
            
        </div>
    )
}

export default Alert