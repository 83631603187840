import React, { useEffect, useState } from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const Clients = () => {
    const [centerModeValue, setCenterModeValue] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
        if (isMobile) {
            setCenterModeValue(false)
            console.log("centerModeVaqqlue", centerModeValue)
        } else {
            setCenterModeValue(true)
            console.log("centerModeValussse", centerModeValue)
        }
    }, [])
    const imgStyle = {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        margin: "auto"
    }
    return (
        <section id="testimonials" class="testimonials section-bg">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>Clients</h2>
                    <p>Clients</p>
                </div>
                <Carousel
                    showArrows={false}
                    autoPlay={false}
                    autoFocus={true}
                    centerMode={centerModeValue}
                    centerSlidePercentage={50}
                    infiniteLoop={2}
                    dynamicHeight={false}
                    thumbWidth={true}
                >
                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                            <img src="/home/assets/img/clients/1.png" style={imgStyle} alt="" />
                            <h3>Hughes Communications India</h3>
                            {/* <h4>Ceo &amp; Founder</h4> */}
                            <p>
                                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                Our team worked closely with Huge India to develop a comprehensive IT strategy that would support their growth and expansion plans.
                                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>

                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                            <img src="/home/assets/img/clients/2.png" alt="" />
                            <h3>Velocis Systems Pvt. Ltd.</h3>
                            <p>
                                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                Our team worked closely with Velocis to develop and design comprehensive network architecture and install L3 Switches & Routers in many districts of Bihar and Odisa.
                                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>

                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                            <img src="/home/assets/img/clients/3.png" alt="" />
                            <h3>Ellmech Institute of Technology Pvt. Ltd.</h3>
                            <p>
                                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                Our team of IT experts worked closely with Ellmech Institute of Technology Pvt. Ltd to understand their unique needs and challenges. We developed a customized IT solution that addressed their specific requirements and helped them achieve their business objectives.
                                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>

                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                            <img src="/home/assets/img/clients/4.png" alt="" />
                            <h3>Hitachi India Pvt. Ltd.</h3>
                            <p>
                                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                Through our partnership with Hitachi India pvt ltd, we helped them PAN India provided Network support and man power. We are proud to have played a key role in their success and look forward to continuing to work with them in the future.
                                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>

                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                            <img src="/home/assets/img/clients/5.png" alt="" />
                            <h3>Bharti Airtel</h3>

                            <p>
                                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                Our IT solution included the implementation of state-of-the-art networking and security systems, cloud computing, and data backup and recovery solutions. We also provided comprehensive training to Bharti Airtel.
                                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                    <div class="testimonial-wrap">
                        <div class="testimonial-item">
                            <img src="/home/assets/img/clients/6.png" alt="" />
                            <h3>IDFC FIRST Bank</h3>
                            <p>
                                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                we worked with IDFC FIRST Bank to optimize their IT processes and streamline their operations, resulting in improved efficiency and cost savings.
                                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                </Carousel>


            </div>
        </section>
    )
}

export default Clients