import React, { useEffect } from 'react'

const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <section id="services" className="services">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Services</h2>
                    <p>Check our Services</p>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bxl-dribbble" /></div>
                            <h4><a href>Network design and implementation</a></h4>
                            <p>At our company, we specialize in network design and implementation services that are customized to fit the specific needs of your organization. We understand that a reliable and efficient network is critical for the success of your business, and our team of experienced network engineers can help you create a solution that meets your unique requirements.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay={200}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-file" /></div>
                            <h4><a href>Network security assessments and solutions</a></h4>
                            <p>At our company, we understand the importance of network security in protecting your business from cyber threats. That's why we offer comprehensive network security assessments and solutions to help you identify vulnerabilities and secure your network against potential attacks.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-tachometer" /></div>
                            <h4><a href>Network monitoring and management</a></h4>
                            <p>At our company, we understand that a reliable and efficient network is critical to the success of your business. That's why we offer comprehensive network monitoring and management services to help you keep your network running smoothly and minimize downtime</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={100}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-world" /></div>
                            <h4><a href>Cloud infrastructure design and management</a></h4>
                            <p>At our company, we specialize in cloud infrastructure design and management services to help businesses leverage the power of the cloud. Our team of experienced cloud engineers can help you design and implement a cloud infrastructure that meets your specific business needs, whether you are looking to migrate your existing infrastructure to the cloud or start from scratch.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={200}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-slideshow" /></div>
                            <h4><a href>Server setup and maintenance</a></h4>
                            <p>At our company, we understand that servers are the backbone of any IT infrastructure. That's why we offer comprehensive server setup and maintenance services to help you keep your servers running smoothly and minimize downtime.
                                <li>Server installation</li>
                                <li>Server security</li>
                                <li>Server backups and disaster recovery</li>
                                <li>Server maintenance</li>
                                <li>Server monitoring</li>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-cloud" /></div>
                            <h4><a href>Virtualization services</a></h4>
                            <p>At our company, we understand the benefits that virtualization can bring to your IT infrastructure. That's why we offer comprehensive virtualization services to help you optimize your IT resources and reduce costs.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-wifi" /></div>
                            <h4><a href>Software and hardware procurement</a></h4>
                            <p>At our company, we understand that the procurement of software and hardware is an important part of your IT infrastructure. That's why we offer comprehensive software and hardware procurement services to help you identify and purchase the right solutions for your specific needs.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-slideshow" /></div>
                            <h4><a href>Network and system audits</a></h4>
                            <p>At our company, we understand the importance of maintaining a healthy and secure IT environment. That's why we offer comprehensive network and system audits to help you identify any vulnerabilities and areas for improvement.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-cloud" /></div>
                            <h4><a href>Firewall configuration and management</a></h4>
                            <p>At our company, we understand the critical role that firewalls play in protecting your IT infrastructure from unauthorized access and cyber threats. That's why we offer comprehensive firewall configuration and management services to help you maintain a secure and reliable IT environment.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-mobile" /></div>
                            <h4><a href>VoIP system setup and management</a></h4>
                            <p>At our company, we understand the importance of reliable and efficient communication in today's business world. That's why we offer comprehensive VoIP (Voice over Internet Protocol) system setup and management services to help you stay connected with your clients and colleagues.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-support" /></div>
                            <h4><a href>Remote support and troubleshooting</a></h4>
                            <p>At our company, we understand that IT issues can arise at any time and can be a major disruption to your business operations. That's why we offer comprehensive remote support and troubleshooting services to help you quickly resolve any IT issues you may be experiencing.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-wifi" /></div>
                            <h4><a href>Wireless network implementation and management</a></h4>
                            <p>In today's business world, wireless connectivity has become essential for staying connected and productive. That's why we offer comprehensive wireless network implementation and management services to help you set up and maintain a reliable and secure wireless network infrastructure.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-phone" /></div>
                            <h4><a href>IP Based Epabx systems</a></h4>
                            <p>Our company specializes in IP-based EPABX systems that provide advanced features and functionality to improve your business communications. An IP-based EPABX (Electronic Private Automatic Branch Exchange) system is a modern communication solution that allows businesses to manage their voice and data communications more effectively. With an IP-based EPABX system, you can unify your communications network and integrate voice, data, and video services into a single platform. Our team of experienced technicians can help you design, install, and maintain an IP-based EPABX system that meets your business needs and budget. Contact us today to learn more about our IP-based EPABX systems and how we can help improve your business communications. </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-cctv" /></div>
                            <h4><a href>CCTV solutions</a></h4>
                            <p>Our CCTV solutions are designed to be scalable, flexible, and easy to use. We offer a wide range of CCTV cameras, recording devices, and monitoring software to provide you with a comprehensive security solution. Our team of experienced technicians can help you design, install, and maintain a CCTV system that meets your specific security needs. Contact us today to learn more about our CCTV solutions and how we can help you protect your business.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-world" /></div>
                            <h4><a href>Digital Marketing</a></h4>
                            <p>At A to Z Infinity Services, we understand the importance of digital marketing in today's competitive business landscape. That's why we offer a range of digital marketing services designed to help businesses of all sizes reach their target audience and grow their online presence.</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services