import React from 'react'

const EndPointSecurity = () => {
    const imgStyle = {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        margin: "auto"
    }
    return (
        <section id="endpointsecurity" className="endpointsecurity">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>End Point Security</h2>
                    <p>End Point Security</p>
                </div>
                <div className="row content">
                    <div className="col-lg-6">
                        <p>
                            At our company, we offer comprehensive endpoint security solutions to protect our clients' devices and networks from a range of cyber threats. Our team of experts works closely with clients to understand their specific security needs and design customized solutions that are both effective and cost-efficient.<br />
                            
                        </p>
                        <img src="/home/assets/img/solutions/anti_virus_1.jpg" alt="Example img" style={imgStyle}></img>

                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <p>
                            Our endpoint security solutions include advanced threat detection and response, anti-virus and anti-malware protection, data encryption, firewall and intrusion prevention, and device management. We use the latest technologies and best practices to ensure that our clients' networks and devices are protected from cyber threats at all times.<br />

                        </p>
                        <img src="/home/assets/img/solutions/anti_virus_2.jpg" alt="Example img" style={imgStyle}></img>
                        <p><br/>
                            Our endpoint security services are designed to be scalable and adaptable, so they can grow and evolve with our clients' businesses. We offer 24/7 support and monitoring to ensure that our clients' devices and networks are secure and protected, even during off-hours.
                        </p>
                        {/* <Link to="#" className="btn-learn-more">Learn More</Link> */}
                    </div>
                </div>

            </div>
        </section>
    )
}

export default EndPointSecurity