import React from 'react'

const Faq = () => {
    return (
        <section id="faq" className="faq">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>F.A.Q</h2>
                    <p>Frequently Asked Questions</p>
                </div>
                <div className="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
                    <div className="col-lg-5">
                        <i className="bx bx-help-circle" />
                        <h4>What IT services do you offer?</h4>
                    </div>
                    <div className="col-lg-7">
                        <p>
                            We offer a wide range of IT services, including network design and implementation, network security assessments and solutions, cloud infrastructure design and management, server setup and maintenance, virtualization services, software and hardware procurement, and more.
                        </p>
                    </div>
                </div>{/* End F.A.Q Item*/}
                <div className="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={200}>
                    <div className="col-lg-5">
                        <i className="bx bx-help-circle" />
                        <h4>How do you ensure the security of my network?</h4>
                    </div>
                    <div className="col-lg-7">
                        <p>
                            We use a variety of industry-standard tools and best practices to secure your network, including firewalls, intrusion detection and prevention systems, anti-virus and anti-malware software, access controls, and regular security audits.
                        </p>
                    </div>
                </div>{/* End F.A.Q Item*/}
                <div className="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={300}>
                    <div className="col-lg-5">
                        <i className="bx bx-help-circle" />
                        <h4>Can you help me migrate my business to the cloud?</h4>
                    </div>
                    <div className="col-lg-7">
                        <p>
                            Yes, we offer cloud infrastructure design and management services that can help you migrate your business to the cloud, whether you need to move your entire infrastructure or just a few key applications..
                        </p>
                    </div>
                </div>{/* End F.A.Q Item*/}
                <div className="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={400}>
                    <div className="col-lg-5">
                        <i className="bx bx-help-circle" />
                        <h4>What kind of support do you offer?</h4>
                    </div>
                    <div className="col-lg-7">
                        <p>
                            We offer a range of support options for PAN India, including remote support and troubleshooting, on-site support, and 24/7 emergency support.
                        </p>
                    </div>
                </div>{/* End F.A.Q Item*/}
                <div className="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={500}>
                    <div className="col-lg-5">
                        <i className="bx bx-help-circle" />
                        <h4>How do I get started?</h4>
                    </div>
                    <div className="col-lg-7">
                        <p>
                            Simply contact us to schedule a consultation with one of our experienced IT professionals. We'll work with you to assess your needs and develop a customized solution that meets your unique requirements. From there, we'll handle the implementation and ongoing management of your IT infrastructure, so you can focus on growing your business.
                        </p>
                    </div>
                </div>{/* End F.A.Q Item*/}
            </div>
        </section>
    )
}

export default Faq