import React from 'react'
import Header from './Header'
import Topslider from './Topslider'
import Aboutus from './Aboutus'
import Counts from './Counts'
import Whyus from './Whyus'
import Services from './Services'
import DataNetwork from './Solutions/DataNetwork'
import NetworkSecurity from './Solutions/NetworkSecurity'
import Wireless from './Solutions/Wireless'
import IpTelephony from './Solutions/IpTelephony'
import EndPointSecurity from './Solutions/EndPointSecurity'
import DevopsSolution from './Solutions/DevopsSolution'
import DigitalMarketing from './Solutions/DigitalMarketing'
// import Testimonials from './Testimonials'
import Cta from './Cta'
// import Portfolio from './Portfolio'
import Team from './Team'
// import Pricing from './Pricing'
import Faq from './Faq'
import Clients from './Clients'
import Contact from './Contact'
import Footer from './Footer'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


const index = () => {
  return (
    <div>

      <Router>
        <Header />
        <Routes>
          <Route path='/' element={[
            <Topslider />,
            <Aboutus />,
            <Counts />,
            <Whyus />,
            <Services />,
            // <Portfolio />,
            <Clients />,
            <Cta />,
            // <Testimonials/>,
            <Team />,
            <Faq />,
            <Contact />
          ]} />
          <Route path='/about' element={<Aboutus />} />
          <Route path='/services' element={<Services />} />
          <Route path='/solutions' element={[<DataNetwork />, <NetworkSecurity />, <Wireless />, <IpTelephony />, <EndPointSecurity />, <DevopsSolution />, <DigitalMarketing />]} />
          <Route path='/datanetwork' element={<DataNetwork />} />
          <Route path='/networksecurity' element={<NetworkSecurity />} />
          <Route path='/wireless' element={<Wireless />} />
          <Route path='/iptelephony' element={<IpTelephony />} />
          <Route path='/endpointsecurity' element={<EndPointSecurity />} />
          <Route path='/devops' element={<DevopsSolution />} />
          <Route path='/digitalmarketing' element={<DigitalMarketing />} />
          <Route path='/clients' element={<Clients />} />
          {/* <Route path='/testimonials' element={<Testimonials/>}/> */}
          {/* <Route path='/portfolio' element={<Portfolio />} /> */}
          <Route path='/team' element={<Team />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        <Footer />
      </Router>




    </div>
  )
}

export default index