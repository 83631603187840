import React from 'react'
import { Link } from 'react-router-dom'

const Counts = () => {
    return (
        <section id="counts" className="counts">
            <div className="container" data-aos="fade-up">

                <div className="row no-gutters">

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="icofont-simple-smile"></i>
                            <span data-toggle="counter-up">183</span>
                            <p><strong>Happy Clients</strong> The results of those who very happy with us.</p>
                            <Link href="#">Find out more &raquo;</Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="icofont-document-folder"></i>
                            <span data-toggle="counter-up">196</span>
                            <p><strong>Projects</strong> The result which we have done.</p>
                            <Link href="#">Find out more &raquo;</Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="icofont-live-support"></i>
                            <span data-toggle="counter-up">1,463</span>
                            <p><strong>Hours Of Support</strong> Our pleasurable hours</p>
                            <Link href="#">Find out more &raquo;</Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="icofont-users-alt-5"></i>
                            <span data-toggle="counter-up">56</span>
                            <p><strong>Hard Workers</strong> Pan India available for every time</p>
                            <Link href="#">Find out more &raquo;</Link>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default Counts