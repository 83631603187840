import React, { useEffect } from 'react'

const DigitalMarketing = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const imgStyle = {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        margin: "auto"
    }
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Digital Marketing</h2>
                    <p>Digital Marketing</p>
                </div>
                <div className="row content">
                    <div className="col-lg-6">
                        <p>
                            Digital marketing is a crucial aspect of any successful business today. It's the practice of promoting your brand, products, or services through digital channels such as search engines, social media, email, mobile apps, and websites. At A to Z Infiny Services, we offer a range of digital marketing services to help your business reach its full potential.
                        </p>
                        <img src="/home/assets/img/solutions/degital_marketing_2.jpg" alt="Example img" style={imgStyle}></img><br />
                        <ul>
                            <li><i className="ri-check-double-line"></i><b>Search Engine Optimization (SEO):</b> We can help improve your website's ranking on search engines such as Google, Bing, and Yahoo, by optimizing your website's content and structure, keyword research, link building, and other proven strategies.</li>
                            <li><i className="ri-check-double-line"></i><b> Pay-Per-Click (PPC) Advertising:</b> Our PPC advertising services help drive targeted traffic to your website, by creating and optimizing ads on search engines and social media platforms such as Google Ads, Facebook Ads, and LinkedIn Ads.</li>
                            <li><i className="ri-check-double-line"></i><b> Social Media Marketing:</b> We can help you leverage the power of social media to connect with your target audience, build brand awareness, and drive engagement through organic and paid social media campaigns.</li>
                            <li><i className="ri-check-double-line"></i><b> Email Marketing:</b> Our email marketing services help you create and send personalized and engaging email campaigns that can help you nurture leads, convert prospects, and retain customers.
                            </li>
                            <li><i className="ri-check-double-line"></i><b>Content Marketing:</b> Our content marketing services can help you create and promote high-quality content that resonates with your target audience, drives traffic, and generates leads.
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <img src="/home/assets/img/solutions/degital_marketing_2.png" alt="Example img" style={imgStyle}></img><br />
                        <p>
                            At A to Z Infinity Services, we take a data-driven approach to digital marketing, by analyzing and optimizing your campaigns for maximum results. Our team of experts will work closely with you to understand your business objectives, target audience, and competition, to create a custom digital marketing strategy that's tailored to your specific needs.
                        </p>
                        <p>
                            <img src="/home/assets/img/solutions/social_media_1.jpg" alt="Example img" style={imgStyle}></img><br />
                            Contact us today to get more about our digital marketing services and how we can help your business succeed online.
                        </p>
                        {/* <Link to="#" className="btn-learn-more">Learn More</Link> */}
                    </div>
                </div>

            </div>
        </section>
    )
}

export default DigitalMarketing