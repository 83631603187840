import React from 'react'
import { Link } from 'react-router-dom'

const Whyus = () => {
    return (
        <section id="why-us" className="why-us section-bg">
            <div className="container-fluid" data-aos="fade-up">

                <div className="row">

                    <div className="col-lg-5 align-items-stretch video-box" style={{ backgroundImage: `url("home/assets/img/why-us.jpg")` }} data-aos="zoom-in" data-aos-delay="100">
                        <Link to="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></Link>
                    </div>

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">

                        <div className="content">
                            <h3>Why you should choose us for your  <strong>network and IT services</strong></h3>
                            <p>
                                We believe that our success is closely tied to the success of our clients. Here are some reasons why you should choose us for your network and IT services:
                            </p>
                        </div>

                        <div className="accordion-list">
                            <ul>
                                <li>
                                    <a data-toggle="collapse" className="collapse" href="#accordion-list-1"><span>01</span> Expertise:<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-1" className="collapse show" data-parent=".accordion-list">
                                        <p>
                                            Our team of IT professionals has years of experience in the industry and stays up-to-date with the latest technologies and trends.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <a data-toggle="collapse" href="#accordion-list-2" className="collapsed"><span>02</span> Customer Service: <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-2" className="collapse" data-parent=".accordion-list">
                                        <p>
                                            We are committed to providing excellent customer service and support. Our team is always available to answer any questions or concerns you may have.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <a data-toggle="collapse" href="#accordion-list-3" className="collapsed"><span>03</span> Flexibility: <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-3" className="collapse" data-parent=".accordion-list">
                                        <p>
                                            We offer flexible IT solutions that can be customized to meet the unique needs of your business.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <a data-toggle="collapse" href="#accordion-list-4" className="collapsed"><span>04</span> Affordability: <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-4" className="collapse" data-parent=".accordion-list">
                                        <p>
                                            We offer competitive pricing for our IT services, so you can get the most value for your investment.
                                        </p>
                                    </div>
                                </li>

                            </ul>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    )
}

export default Whyus