import React, { useEffect } from 'react'

const IpTelephony = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const imgStyle = {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        margin: "auto"
    }
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>IP Telephony</h2>
                    <p>IP Telephony</p>
                </div>
                <div className="row content">
                    <div className="col-lg-6">
                        <p>
                            IP telephony, also known as Voice over Internet Protocol (VoIP), is a popular communication solution used by businesses of all sizes. This technology allows users to make voice and video calls over the internet, rather than through traditional phone lines.<br />

                            At our company, we specialize in providing IP telephony services to our clients. Our team of experienced professionals can design and implement a customized VoIP solution that meets the unique needs of your business.
                        </p>
                        <img src="/home/assets/img/solutions/iptelephone.jpg" alt="Example img" style={imgStyle}></img>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <p>
                            Our mission is to help businesses optimize their network and IT infrastructure to improve efficiency, productivity, and security. We understand that each business has unique needs and goals, and that's why we offer customized solutions tailored to meet those specific needs. Whether you're looking for ongoing network monitoring and maintenance, cloud-based solutions, or help with network security, we have the expertise to help you succeed.
                        </p>
                        <p>
                            We can provide you with high-quality IP phones that are designed to enhance productivity and collaboration in the workplace. Our IP telephony services also include features such as call forwarding, voicemail, conferencing, and more.
                        </p>
                        {/* <Link to="#" className="btn-learn-more">Learn More</Link> */}
                    </div>
                </div>

            </div>
        </section>
    )
}

export default IpTelephony