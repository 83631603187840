import React, { useEffect } from 'react'

const DataNetwork = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const imgStyle = {
        maxWidth: "100%",
        height: "auto",
        display: "block",
        margin: "auto"
    }
    return (
        <section id="solutions" className="solutions">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Data Network</h2>
                    <p>Data Network</p>
                </div>
                <div className="row content">
                    <div className="col-lg-6">
                        <p>
                            Data networks are the backbone of modern businesses. They allow employees to communicate and collaborate effectively, and enable businesses to operate efficiently and securely. At A to Z Infinity Services, we specialize in designing and implementing data networks that meet the unique needs of our clients. <br />
                            Whether you need a new network installed, an existing network upgraded, or ongoing network management and support, we have the expertise to help. We offer a wide range of data network services, including:

                        </p>
                        <ul>
                            <li><i className="ri-check-double-line"></i> Network design and implementation.</li>
                            <li><i className="ri-check-double-line"></i> Network security assessments and solutions.</li>
                            <li><i className="ri-check-double-line"></i> Network monitoring and management.</li>
                            <li><i className="ri-check-double-line"></i> Cloud infrastructure design and management.</li>
                            <li><i className="ri-check-double-line"></i> Server setup and maintenance.</li>
                            <li><i className="ri-check-double-line"></i> Wireless network implementation and management.</li>
                        </ul>
                        <img src="/home/assets/img/solutions/switch-g56a32223a_1920.jpg" alt="Example img" style={imgStyle}></img>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <p>
                            <h5>Switching</h5>
                            Switches are an essential component of any modern computer network. A switch is a networking device that connects devices such as computers, printers, and servers, allowing them to communicate with each other. Switches provide a central point of control for network traffic, ensuring that data is routed efficiently and reliably between devices.
                        </p>

                        <img src="/home/assets/img/solutions/switch_1.jpg" alt="Example img" style={imgStyle}></img>
                        <p>
                            <h5>Routing</h5>
                            A router is a networking device that connects multiple networks and routes data packets between them. Routers are essential for connecting devices to the internet, as well as for enabling communication between different devices and networks within a larger network.
                        </p>

                        <img src="/home/assets/img/solutions/network_router_1.jpg" alt="Example img" style={imgStyle}></img>

                        {/* <Link to="#" className="btn-learn-more">Learn More</Link> */}
                    </div>
                </div>

            </div>
        </section>
    )
}

export default DataNetwork