import React from 'react'
import { Link } from 'react-router-dom'


const Topslider = () => {
    return (
        <section id="hero">
            <div id="heroCarousel" className="carousel slide carousel-fade" data-ride="carousel">

                <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

                <div className="carousel-inner" role="listbox">

                    {/* <!-- Slide 1 --> */}
                    <div className="carousel-item active" style={{ backgroundImage: `url("/home/assets/img/slide/slide-1.jpg")` }}>
                        <div className="carousel-container">
                            <div className="container">
                                <h2 className="animate__animated animate__fadeInDown"><span>Pan India Customer Services</span></h2>
                                <p className="animate__animated animate__fadeInUp"> At A to Z Infinity Services, we are committed to providing the highest levels of customer service to our clients across India. With our pan India customer service, you can be assured of prompt and professional support, no matter where your business is located.</p>
                                <Link to="contact" className="btn-get-started animate__animated animate__fadeInUp scrollto">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                    {/* Slide 2  */}
                    <div className="carousel-item " style={{ backgroundImage: `url("/home/assets/img/slide/slide-1.jpg")` }}>
                        <div className="carousel-container">
                            <div className="container">
                                <h2 className="animate__animated animate__fadeInDown">Welcome to <span>A to Z Infinity Services </span></h2>
                                <p className="animate__animated animate__fadeInUp"> Your trusted provider of comprehensive network engineering solutions. We understand that a robust and reliable network is critical to the success of your business, and that's why we're here to help you design, implement, and maintain a network infrastructure that meets your specific needs.</p>
                                <Link to="services" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</Link>
                            </div>
                        </div>
                    </div>

                    {/*  Slide 3  */}
                    <div className="carousel-item" style={{ backgroundImage: `url("/home/assets/img/slide/slide-2.jpg")` }}>
                        <div className="carousel-container">
                            <div className="container">
                                <h2 className="animate__animated animate__fadeInDown">Our vision</h2>
                                <p className="animate__animated animate__fadeInUp">Your trusted partner for all your networking needs. Our team of experienced network engineers is dedicated to providing high-quality networking solutions that meet the unique needs of your business. Whether you're looking to improve the speed and reliability of your network, or need help designing and implementing a new network infrastructure, we're here to help.</p>
                                <Link to="about" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</Link>
                            </div>
                        </div>
                    </div>

                    {/*  Slide 4  */}
                    <div className="carousel-item" style={{ backgroundImage: `url("/home/assets/img/slide/slide-3.jpg")` }}>
                        <div className="carousel-container">
                            <div className="container">
                                <h2 className="animate__animated animate__fadeInDown">Our mission</h2>
                                <p className="animate__animated animate__fadeInUp">Our mission is to help businesses optimize their network and IT infrastructure to improve efficiency, productivity, and security. We understand that each business has unique needs and goals, and that's why we offer customized solutions tailored to meet those specific needs. Whether you're looking for ongoing network monitoring and maintenance, cloud-based solutions, or help with network security, we have the expertise to help you succeed.</p>
                                <Link to="networksecurity" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</Link>
                            </div>
                        </div>
                    </div>

                </div>

                <a className="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon icofont-simple-left" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>

                <a className="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                    <span className="carousel-control-next-icon icofont-simple-right" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>

            </div>
        </section>
    )
}

export default Topslider