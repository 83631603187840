const Validation = (value) => {
    let errors = {}
    if(!value.name){
        errors.name = "Name is required";
    }
    else if (value.name.length < 2 ){
        errors.name = "Name must be more than 2 charector"
    }
    if(!value.email){
        errors.email = "Email is required";
    }
    if(!value.zip_code){
        errors.zip_code = "Zip code is required";
    }
    else if(value.zip_code.length !== 6){
        errors.zip_code = "Zip code should be 6 charector";
    }
    


    return errors;
}

export default Validation;