import React, { useEffect } from 'react'

const Team = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Team</h2>
                    <p>Check our Team</p>
                </div>
                <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="member" data-aos="zoom-in" data-aos-delay={100}>
                            <img src="home/assets/img/team/sumit_pic_1.png" className="img-fluid" alt="" />
                            <div className="member-info">
                                <div className="member-info-content">
                                    <h4>Mr. Sumit </h4>
                                    <span>Chief Executive Officer(North-East)</span>
                                    <span>(Assam, West Bangal, Arunchal Pardesh, Manipur, Sikkim, Nagaland)</span>
                                </div>
                                <div className="social">
                                    <a href><i className="icofont-twitter" /></a>
                                    <a href><i className="icofont-facebook" /></a>
                                    <a href><i className="icofont-instagram" /></a>
                                    <a href><i className="icofont-linkedin" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.1s">
                        <div className="member" data-aos="zoom-in" data-aos-delay={200}>
                            <img src="home/assets/img/team/team-2.jpg" className="img-fluid" alt="" />
                            <div className="member-info">
                                <div className="member-info-content">
                                    <h4>Sarah Jhonson</h4>
                                    <span>Product Manager</span>
                                </div>
                                <div className="social">
                                    <a href><i className="icofont-twitter" /></a>
                                    <a href><i className="icofont-facebook" /></a>
                                    <a href><i className="icofont-instagram" /></a>
                                    <a href><i className="icofont-linkedin" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.2s">
                        <div className="member" data-aos="zoom-in" data-aos-delay={300}>
                            <img src="home/assets/img/team/team-3.jpg" className="img-fluid" alt="" />
                            <div className="member-info">
                                <div className="member-info-content">
                                    <h4>William Anderson</h4>
                                    <span>CTO</span>
                                </div>
                                <div className="social">
                                    <a href><i className="icofont-twitter" /></a>
                                    <a href><i className="icofont-facebook" /></a>
                                    <a href><i className="icofont-instagram" /></a>
                                    <a href><i className="icofont-linkedin" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.3s">
                        <div className="member" data-aos="zoom-in" data-aos-delay={400}>
                            <img src="home/assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                            <div className="member-info">
                                <div className="member-info-content">
                                    <h4>Amanda Jepson</h4>
                                    <span>Accountant</span>
                                </div>
                                <div className="social">
                                    <a href><i className="icofont-twitter" /></a>
                                    <a href><i className="icofont-facebook" /></a>
                                    <a href><i className="icofont-instagram" /></a>
                                    <a href><i className="icofont-linkedin" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team