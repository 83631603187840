import React, { useEffect } from 'react'
// import { Link } from 'react-router-dom'

const Aboutus = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>About</h2>
                    <p>About Us</p>
                </div>
                <div className="row content">
                    <div className="col-lg-6">
                        <p>
                            At A to Z Infinity Services, we are dedicated to providing top-quality network and IT services to help businesses of all sizes stay competitive in today's digital world. Our team of experienced professionals has a deep understanding of the latest technologies and trends, and we use this knowledge to help our clients achieve their business goals.
                        </p>
                        <ul>
                            <li><i className="ri-check-double-line"></i> Managed IT Services: We provide complete managed IT services for businesses that want to outsource their IT management to an expert team. Our managed IT services include network monitoring, data backup and recovery, security and compliance management, and more.</li>
                            <li><i className="ri-check-double-line"></i> Network Engineering: Our team of experienced network engineers provides a range of network design and implementation services, including network infrastructure planning, network hardware installation, and network configuration.</li>
                            <li><i className="ri-check-double-line"></i> Cloud Solutions: We offer a range of cloud solutions to help businesses reduce costs, increase efficiency, and improve collaboration. Our cloud services include cloud migration, cloud hosting, and cloud consulting.</li>
                            <li><i className="ri-check-double-line"></i> Cybersecurity: Our team of cybersecurity experts can help you protect your business against cyber threats such as phishing, malware, and ransomware. We provide services such as vulnerability assessments, network security, and employee cybersecurity training.</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0">
                        <p>
                            Our mission is to help businesses optimize their network and IT infrastructure to improve efficiency, productivity, and security. We understand that each business has unique needs and goals, and that's why we offer customized solutions tailored to meet those specific needs. Whether you're looking for ongoing network monitoring and maintenance, cloud-based solutions, or help with network security, we have the expertise to help you succeed.
                        </p>
                        {/* <Link to="#" className="btn-learn-more">Learn More</Link> */}
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Aboutus