import React from 'react'
import { Link } from 'react-router-dom';

const WhatsAppButton = () => {

    const phoneNumber = '+8103197774';
    const message = 'Hello! I have a question about your services.';
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    return (
        <div className="whatsapp_float">
            <ul className="nobullets">
                <li>
                    <a  href={url} target="_blank" rel="noopener noreferrer" >
                        <i className="fa fa-whatsapp whatsapp-icon"></i>
                    </a>
                </li>
                <li>
                    <Link  to="/contact">
                        <i className="fa fa-envelope envelope-icon"></i>
                    </Link> 
                </li>
                <li>
                    <a   href="tel:+8103197774">
                        <i className="fa fa-phone phone-icon "></i>
                    </a>
                </li>
            </ul>

        </div>
    );
}

export default WhatsAppButton