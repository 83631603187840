import './App.css';
import React from 'react';
// import {BrowserRouter as  Router,Routes,Route} from 'react-router-dom';
import Home from './Home';

function App() {
  return (
    // <Router>
      <div>
        <Home />
        {/* <Routes> */}
          {/* <Route path='/' element={<Home/>} /> */}
        {/* </Routes> */}
      </div>
    // </Router>
    
  );
}

export default App;
